

.Title {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 1em;
    margin-bottom: 2em;
    background-color: rgb(226, 174, 4);
    color: #fff;
  }

.centerBlockInner {
    width: 800px;
    margin: 24px 0 24px 0;
    border-style: solid;
    border-radius: 5px;
    border-color: darkgray
  }

.centerBlockOuter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    margin: 0 18px;
    border-style: none;
}
p{
    text-align: left;
}
td{
   text-align: left;
}
td:first-child {
  width: 180px;
  font-weight: 600;
  word-break: normal;
}
table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  .tableWrap {
    border: 1px solid var(--border);
    border-radius: 6px;
  }

  h2 {
    margin: 0 0 4px 0;
    font-weight: 600;
    font-size: 17px;
  }

  .contentBlock {
    color: var(--text);
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin: 0 0 10px 0;
  }